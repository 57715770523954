import React, { useState } from 'react';
import {
    Form,
    Input,
    Select,
    Tooltip,
    Button,
    Spin,
    Row,
    Col,
    notification,
    DatePicker,
    Checkbox,
    Divider,
    message,
} from 'antd';
import {
    UserOutlined,
    CarOutlined,
    MailOutlined,
    PhoneOutlined,
    CreditCardOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { addRunner, getRunner } from '../../../../api/runner';
import { getAllUserFiltersApi } from '../../../../api/user';

import './AddRunnerForm.scss';
import moment from 'moment';

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 14,
    },
};

const AddRunnerForm = (props) => {
    const { setIsModalVisible, element, groupAdmin, setGroupAdmin } = props;
    const [runnerForm, setRunnerForm] = useState([]);
    const token = getAccessTokenApi();
    // const companyId = getCompanyIdApi();
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const { Option } = Select;

    const [loadingForm, setLoadingForm] = useState(false);
    const [users, setUsers] = useState([]);
    const [disableInputs, setDisableInputs] = useState(true);
    const [valueSelect, setValueSelect] = useState(undefined);
    const [valueVehicle, setValueVehicle] = useState(undefined);

    const [form] = Form.useForm();

    function onChangeSelectUser(value) {
        setValueSelect(value);

        if (value === 'notFound') {
            setDisableInputs(false);
            if (users.length > 0) {
                message.warn(
                    'No se han encontrado runners, Complete los campos'
                );
            }
            form.resetFields();
        } else {
            if (value === undefined) {
                return;
            }
            //fill User
            setDisableInputs(true);
            FillInfoUser(value);
        }
    }

    const FillInfoUser = async (email) => {
        const userFound = users.find((element) => element.email === email);
        const result = await getRunner(token, userFound.id);

        if (result) {
            if (result.statusCode === 200) {
                const userRunner = result.result;
                setRunnerForm([
                    {
                        name: ['Email'],
                        value: userRunner.email,
                    },
                    {
                        name: ['Name'],
                        value: userRunner.name,
                    },
                    {
                        name: ['LastName'],
                        value: userRunner.lastName,
                    },
                    {
                        name: ['birthDay'],
                        value: moment.unix(userRunner.birthDate),
                    },
                    {
                        name: ['phoneNumber'],
                        value: userRunner.phoneNumber,
                    },
                    {
                        name: ['VehicleType'],
                        value: userRunner.vehicle.type,
                    },
                    {
                        name: ['Plate'],
                        value: userRunner.vehicle.plate,
                    },
                ]);
                message.success('Datos Completados Correctamente');
            } else {
                notification['error']({
                    message:
                        'Ocurrio un error al cargar la informacion del usuario. Intentelo de nuevo',
                });
            }
        } else {
            notification['error']({
                message:
                    'Ocurrio un error al cargar la informacion del usuario.',
            });
        }
    };

    // function onBlur() {
    //     console.log('blur');
    // }

    // function onFocus() {
    //     console.log('focus');
    // }

    function onSearch(val) {
        if (val !== '') {
            if (val.length > 3) {
                searchEmail(val);
            }
        }
    }

    const searchEmail = async (val) => {
        const filters = `?Email=${val}&UserType=${2}`;
        const result = await getAllUserFiltersApi(token, filters);

        if (result) {
            if (result.statusCode === 200) {
                if (result.result.length > 0) {
                    setUsers(result.result);
                } else {
                    setUsers([]);
                }
            } else {
                setUsers([]);
            }
        }
    };

    function disabledDate(current) {
        return current > moment().endOf('day');
    }

    const onFinish = async (values) => {
        setLoadingForm(true);
        let formData = values;
        formData['Vehicle'] = {
            type: values.VehicleType,
            plate: values.Plate,
        };
        formData['GroupId'] = element.id;
        if (values.birthDay !== undefined) {
            formData['birthDate'] = values.birthDay.unix();
        }
        const result = await addRunner(token, formData);
        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setLoadingForm(false);
            logout();
            return false;
        }
        if (result.statusCode === 400) {
            notification['error']({
                message: result.description,
            });
            setLoadingForm(false);
            return false;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Runner creado exitosamente',
            });

            // const idCategory = result.result.categoryId;
            const found = groupAdmin.findIndex((obj) => obj.id === element.id);

            const categoryJSON = {
                name: result.result.name,
                lastName: result.result.lastName,
                email: result.result.email,
                phoneNumber: result.result.phoneNumber,
                // birthDay: values.birthDay,
                birthDate: moment.unix(result.result.birthDate),
                vehicle: {
                    type: result.result.vehicle.type,
                    plate: result.result.vehicle.plate,
                    id: result.result.vehicle.id,
                },
                id: result.result.id,
                isOnline: result.result.isOnline,
                active: result.result.active,
                authorized: result.result.authorized,
            };
            groupAdmin[found].companyRunners.push(categoryJSON);

            setGroupAdmin(groupAdmin);

            // groupAdmin[found].categoryId = result.result.name;

            setLoadingForm(false);
            setUsers([]);
            setValueSelect(undefined);
            form.resetFields();
            setIsModalVisible(false);
        } else {
            let messageError =
                'Ocurrio un problema al crear nuevo runner, Intentelo otra vez';

            if (result.statusCode === 404 || result.statusCode === 400) {
                messageError = result.description;
            }
            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
        }
    };

    const onClearUserInfo = () => {
        form.resetFields();
    };

    return (
        <Spin tip="Creando runner..." spinning={loadingForm}>
            <Form
                {...layout}
                className="category"
                form={form}
                onFinish={onFinish}
                fields={runnerForm}
                scrollToFirstError={true}
                onFieldsChange={(changedFields, allFields) => {
                    setRunnerForm(allFields);
                }}
            >
                <Row gutter={24}>
                    {users.length >= 0 && (
                        <Col xs={24} md={24}>
                            <h3>
                                Buscar usuario por correo electrónico o nuevo
                                registro
                            </h3>
                            <Select
                                allowClear
                                showSearch
                                style={{ width: '100%', marginBottom: 20 }}
                                placeholder="Busque a un usuario"
                                optionFilterProp="children"
                                onChange={onChangeSelectUser}
                                value={valueSelect}
                                onClear={onClearUserInfo}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="notFound">
                                    Registrar uno nuevo
                                </Option>
                                {users.map((user) => {
                                    return (
                                        <Option
                                            key={user.id}
                                            value={user.email}
                                        >
                                            {user.email}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    )}

                    <Divider></Divider>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label="Correo Electrónico"
                            name="Email"
                            hasFeedback
                            rules={[
                                {
                                    type: 'email',
                                    message:
                                        'El correo electrónico no es válido',
                                },
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese un correo electrónico',
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Correo electrónico"
                                disabled={disableInputs}

                                // onChange={(e) =>
                                //     setEmailUser(e.target.value)
                                // }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="Name"
                            label="Nombre del runner"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un nombre',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Nombre"
                                disabled={disableInputs}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="LastName"
                            label="Apellido del runner"
                            hasFeedback
                            rules={[
                                {
                                    message: 'Por favor ingrese un apellido',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Apellido"
                                disabled={disableInputs}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="birthDay"
                            label={'Fecha de nacimiento'}
                            rules={[
                                // {
                                //     required: true,
                                //     message:
                                //         'Por favor ingresa tú fecha de nacimiento',
                                // },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value !== undefined) {
                                            const years = moment().diff(
                                                value,
                                                'years',
                                                true
                                            );
                                            if (years >= 18) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(
                                                    'Debe de tener 18 años o más'
                                                );
                                            }
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                disabledDate={disabledDate}
                                className={'picker-widt'}
                                initialValues={moment(
                                    '01/01/2002',
                                    dateFormatList[0]
                                )}
                                format={dateFormatList}
                                disabled={disableInputs}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="phoneNumber"
                            label={
                                <span>
                                    Teléfono&nbsp;
                                    <Tooltip title="Tú número telefónico debe contener 10 dígitos">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingresa tú número telefónico',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            value.toString().length === 10
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Por favor ingresa tú número telefónico a 10 dígitos'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined />}
                                placeholder="Teléfono"
                                disabled={disableInputs}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label="Vehículo"
                            name="VehicleType"
                            hasFeedback
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Por favor seleccione un vehículo',
                            //     },
                            // ]}
                        >
                            <Select
                                placeholder="Selecciona"
                                name="type"
                                prefix={<CarOutlined />}
                                disabled={disableInputs}
                                onChange={(value) => setValueVehicle(value)}
                            >
                                <Option value={0}>Automóvil</Option>
                                <Option value={1}>Motocicleta</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="Plate"
                            label="Placas"
                            hasFeedback
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (valueVehicle === undefined) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                'Por favor ingresa las placas'
                                            );
                                        }
                                    },
                                }),
                                // {

                                //     required: true,
                                //     message: 'Por favor ingrese las placas',
                                //     whitespace: true,

                                // },
                            ]}
                        >
                            <Input
                                prefix={<CreditCardOutlined />}
                                placeholder="ABCD123"
                                disabled={disableInputs}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="Active"
                            valuePropName="checked"
                            label="Activo"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button type="primary" htmlType="submit" block>
                            Crear runner
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default AddRunnerForm;
