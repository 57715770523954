import React from 'react';
import { Layout } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import PaymentsPromos from '../../../components/SuperAdmin/Payments/TablePayments';

import './Payments.scss';

const Payments = () => {
    const { Content } = Layout;

    return (
        <Layout className='payments'>
            <HeaderTop
                title={'Pagos'}
                backIcon={true}
                backUrl='/administrative'
            />
            <Content className='payments__content'>
                <PaymentsPromos />
            </Content>
        </Layout>
    );
};

export default Payments;
