import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
    Typography,
    Skeleton,
    Divider,
    Button,
    Avatar,
    Image,
    List,
    Tabs,
    Card,
    Row,
    Col,
    Spin,
    Empty,
    notification,
    message,
} from 'antd';
import { PlusOutlined, TagFilled, CameraOutlined } from '@ant-design/icons';
import moment from 'moment';
import food from '../../../assets/img/jpg/seafood.jpg';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanySuscriptionApi } from '../../../api/company';
import { AdviceApplySeen, getAllAdvicesCompany } from '../../../api/advice';
import { getCompleteMenuClient, getMenuGroupApi } from '../../../api/menu';
import Modal from '../../../components/General/Modal';
import DescriptionItem from '../../../components/Client/DescriptionItem';
import BasketForm from '../../../components/Client/BasketForm';
import AdviceForm from '../../../components/Client/AdviceForm';
import {
    formatMoney,
    userLogOut,
    suscriptionType,
    messageError,
} from '../../../utils/general';
import useOrder from '../../../hooks/useOrder';

import './MenuCustomer.scss';
import ImageCarousel from './ImageCarousel/ImageCarousel';

const MenuCustomer = (props) => {
    const isMounted = useRef(true);
    const { TabPane } = Tabs;
    const [group, setGroup] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingItems, setLoadingItems] = useState(false);
    const { Title, Text } = Typography;
    const { infoCompany, scheduleCompany, OrderType } = props;
    const { orderGeneral } = useOrder();
    const { Subtotal, orderLinesList, CompanyId } = orderGeneral;
    const token = getAccessTokenApi();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [titleModal, setTitleModal] = useState('');
    const [counterItem, setCounterItem] = useState(0);
    const dayNumber = moment().day() === 0 ? 7 : moment().day();
    const [groupSearches, setGroupSearches] = useState([]);
    const [activeKey, setActiveKey] = useState('');
    const [suscriptionCompany, setSuscriptionCompany] = useState('Basic');
    const [adviceSeen, setAdviceSeen] = useState(false);
    const [isModalVisibleAdvice, setIsModalVisibleAdvice] = useState(false);
    const [contextModalAdvice, setContextModalAdvice] = useState(null);
    const [titleModalAdvice, setTitleModalAdvice] = useState('');
    const [itemActive, setItemActive] = useState(null);
    const now = moment();

    // const [loadingPromo, setLoadingPromo] = useState(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        // console.log('orderLinesList', orderLinesList);
        setCounterItem(orderLinesList.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderGeneral]);

    useEffect(() => {
        const token = getAccessTokenApi();
        if (CompanyId) {
            getCompanySuscriptionApi(token, CompanyId)
                .then((response) => {
                    if (response) {
                        if (response.statusCode === 200) {
                            const result = response.result;
                            setSuscriptionCompany(
                                suscriptionType(result.suscriptionType)
                            );
                        }
                    }
                })
                .catch((err) => {});
        }
    }, [CompanyId]);

    useEffect(() => {
        if (CompanyId === '' || OrderType === '') {
            return false;
        }
        const data = {
            CompanyId: CompanyId,
            OrderType: OrderType,
            MenuClient: true,
        };

        getCompleteMenuClient(token, data).then((group) => {
            if (isMounted.current) {
                if (group !== undefined) {
                    if (group.length > 0) {
                        let idGroupOne = group[0]['groupId'];

                        const dataApi = {
                            CompanyId: CompanyId,
                            GroupId: idGroupOne,
                            OrderType: OrderType,
                            MenuClient: true,
                        };
                        getMenuGroupApi(token, dataApi).then((resp) => {
                            if (resp !== undefined) {
                                group[0] = resp;
                                // console.log(resp);
                                setActiveKey(idGroupOne.toString());
                                setGroup(group);
                                setLoading(false);
                                setGroupSearches([idGroupOne]);
                            } else {
                                setGroup([]);
                                setLoading(false);
                            }
                        });
                    } else {
                        setGroup([]);
                        setLoading(false);
                    }
                } else {
                    notification['error']({
                        message:
                            'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                    });
                }
            }
        });

        return () => {
            setGroup([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CompanyId, OrderType]);

    useEffect(() => {
        let filters = '';
        if (OrderType === 1) {
            filters = `&toGo=true`;
        }
        if (OrderType === 2) {
            filters = `&toDeliver=true`;
        }
        // if(OrderType === 3 ){
        //     filters = `&toTable=true`;
        // }
        getAllAdvicesCompany(token, CompanyId, filters).then((response) => {
            if (response === undefined) {
                const msg = messageError();

                notification['error']({
                    message: msg,
                });
                return;
            }
            if (response.statusCode === 200 && response.result.length > 0) {
                setAdviceSeen(false);
                showModalAdvice(response.result[0], 'Advice');
                setItemActive(response.result[0]);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CompanyId, OrderType, adviceSeen]);

    const callbackGroup = (key) => {
        let idGroup = parseInt(key);
        const foundIdGroup = groupSearches.find((id) => id === idGroup);
        if (foundIdGroup === undefined) {
            setLoadingItems(true);
            let dataApi = {
                CompanyId: orderGeneral.CompanyId,
                GroupId: idGroup,
                OrderType: orderGeneral.OrderType,
                MenuClient: true,
            };
            getMenuGroupApi(token, dataApi).then((resp) => {
                // console.log(resp, 'groupSelec');
                if (isMounted.current) {
                    if (resp !== undefined) {
                        setGroupSearches([...groupSearches, idGroup]);
                        const found = group.findIndex(
                            (item) => item.groupId === resp.groupId
                        );
                        group[found] = resp;
                        setGroup(group);
                        setActiveKey(idGroup.toString());
                        setLoadingItems(false);
                    }

                    if (resp === undefined) {
                        message.error(
                            'Verifique su conexión a internet, si no se resuelve intente más tarde.'
                        );
                        setActiveKey(idGroup.toString());
                        setLoadingItems(false);
                    }
                }
            });
        } else {
            setActiveKey(key);
        }
    };

    const listData = [];
    for (let i = 0; i < 5; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `menuClient ${i}`,
            avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            description:
                'Ant Design, a design language for background applications, is refined by Ant UED Team.',
            content:
                'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
        });
    }

    const showModalAdvice = (item) => {
        setContextModalAdvice(
            <AdviceForm
                item={item}
                setIsModalVisible={setIsModalVisibleAdvice}
                setAdviceSeen={setAdviceSeen}
            ></AdviceForm>
        );
        setTitleModalAdvice(item.name);
        setIsModalVisibleAdvice(true);
        return;
    };

    const showModal = (item, title) => {
        if (title === 'Lista de Imágenes') {
            setContextModal(
                <ImageCarousel
                    itemImage={item.itemImage}
                    itemId={item.itemId}
                />
            );
            setTitleModal(item.name);
            setIsModalVisible(true);
            return;
        }
        // if (title === 'Advice') {
        //     setContextModal(
        //         <AdviceForm
        //             item={item}
        //             setIsModalVisible={setIsModalVisible}
        //             setAdviceSeen={setAdviceSeen}
        //         ></AdviceForm>
        //     );
        //     setTitleModal(item.name);
        //     setIsModalVisible(true);
        //     return;
        // }
        if (infoCompany !== undefined) {
            //CHECK IF EXIST ORDER ACTIVE AND RESTAURANT ALLOW MULTI ORDER ACTIVE
            if (!infoCompany.AllowMultipleActiveOrders) {
                if (infoCompany.ActiveOrder !== undefined) {
                    if (infoCompany.ActiveOrder) {
                        notification['info']({
                            message: 'Ya tienes una orden en proceso',
                            description:
                                'Debes finalizar una orden para poder realizar una nueva',
                        });
                        return false;
                    }
                }
            } else {
                // if (infoCompany.ActiveOrder !== undefined) {
                //     if (infoCompany.ActiveOrder) {
                //         notification['info']({
                //             message: 'Ya tienes una orden en proceso',
                //         });
                //     }
                // }
            }
        } else {
            notification['error']({
                message: 'Ocurrio un error al cargar la información',
            });
            setTimeout(() => {
                window.location.href = '/login';
            }, 1500);
            return;
        }

        if (title === 'Order') {
            setContextModal(
                <BasketForm
                    setCounterItem={setCounterItem}
                    setIsModalVisible={setIsModalVisible}
                    infoCompany={infoCompany}
                    suscriptionCompany={suscriptionCompany}
                    // setTotalOrder={setTotalOrder}
                    // order={orderGeneral}
                />
            );
            setTitleModal('Orden');
            setIsModalVisible(true);
        } else {
            if (scheduleCompany !== null) {
                if (scheduleCompany.Schedule.length === 0) {
                    let msg = `El restaurante esta temporalmente cerrado.`;
                    message.warning(msg, 3);
                    setIsModalVisible(false);
                    return false;
                }
            } else {
                notification['error']({
                    message: 'Ocurrio un error al cargar la información',
                });
                setTimeout(() => {
                    window.location.href = '/login';
                }, 1500);
                return;
            }

            const validateSchedule = validSchedule();

            if (validateSchedule.isClose) {
                let msg = `Lo sentimos el establecimiento esta cerrado este día. ${validateSchedule.message}`;
                message.warning(msg, 3);
                setIsModalVisible(false);
                return false;
            }

            if (!validateSchedule.isClose && !validateSchedule.isBetween) {
                let msg = `Lo sentimos el establecimiento esta cerrado.  ${validateSchedule.message}`;
                message.warning(msg, 3);
                setIsModalVisible(false);
                return false;
            }

            setContextModal(
                <DescriptionItem
                    item={item}
                    setIsModalVisible={setIsModalVisible}
                    suscriptionCompany={suscriptionCompany}
                    // setCounterItem={setCounterItem}
                    // order={order}
                    // setOrder={setOrder}
                    // setTotalOrder={setTotalOrder}
                    // totalOrder={totalOrder}
                />
            );
            setTitleModal(title);
            setIsModalVisible(true);
        }
    };

    const validSchedule = () => {
        let now = moment().format('YYYY-MM-DD');
        const dayFound = scheduleCompany.Schedule.find(
            (element) => element.dayOfWeek === dayNumber
        );

        // let nextDay = 0;
        let response = {
            isClose: true,
            isBetween: false,
            nextDay: 0,
            message: '',
        };

        if (dayNumber >= 1 && dayNumber <= 6) {
            response.nextDay = dayNumber + 1;
        } else {
            response.nextDay = 1;
        }

        if (dayFound !== undefined) {
            //is open
            let timeDay = {
                timeStart: moment(`${now} ${dayFound.timeStart}`),
                timeEnd: moment(`${now} ${dayFound.timeEnd}`),
            };

            if (moment(timeDay.timeEnd).isBefore(timeDay.timeStart)) {
                //LA HORA FINAL ES ANTES QUE LA FINAL
                //SUMARLE UN DIA
                timeDay.timeEnd = timeDay.timeEnd.add(1, 'd');
            }

            let isBetween = moment().isBetween(
                timeDay.timeStart,
                timeDay.timeEnd
            ); // true

            response.isClose = false;
            response.isBetween = isBetween;

            if (!isBetween) {
                const isBeforeStart = moment().isBefore(timeDay.timeStart);

                if (isBeforeStart) {
                    response.message = `Apertura: ${timeDay.timeStart.format(
                        'h:mm A'
                    )} a ${timeDay.timeEnd.format('h:mm A')} `;
                } else {
                    response = NextOpening(response, now);
                }
            }
        } else {
            //is not open this day
            response = NextOpening(response, now);
        }

        return response;
    };

    const NextOpening = (response, now) => {
        let findNextDay = false;

        while (!findNextDay) {
            const dayNextFound = scheduleCompany.Schedule.find(
                (element) => element.dayOfWeek === response.nextDay
            );

            if (dayNextFound === undefined) {
                if (response.nextDay >= 1 && response.nextDay <= 6) {
                    response.nextDay = response.nextDay + 1;
                } else {
                    response.nextDay = 1;
                }
            } else {
                response.nextDayTimeStart = moment(
                    `${now} ${dayNextFound.timeStart}`
                );
                response.nextDayTimeEnd = moment(
                    `${now} ${dayNextFound.timeStart}`
                );

                let dayString = '';
                switch (dayNextFound.dayOfWeek) {
                    case 1:
                        dayString = 'Lunes';
                        break;
                    case 2:
                        dayString = 'Martes';
                        break;
                    case 3:
                        dayString = 'Miércoles';
                        break;
                    case 4:
                        dayString = 'Jueves';
                        break;
                    case 5:
                        dayString = 'Viernes';
                        break;
                    case 6:
                        dayString = 'Sábado';
                        break;
                    case 7:
                        dayString = 'Domingo';
                        break;

                    default:
                        break;
                }
                response.message = `Próxima apertura: ${dayString} de ${moment(
                    `${now} ${dayNextFound.timeStart}`
                ).format('h:mm A')} a ${moment(
                    `${now} ${dayNextFound.timeEnd}`
                ).format('h:mm A')} `;
                findNextDay = true;
            }
        }

        return response;
    };

    const showPriceBefore = (promo) => {
        let showPrice = false;
        if (promo) {
            const { type } = promo;

            if (type === 3 || type === 4) {
                showPrice = isValidPromo(promo);
            }
        }

        return showPrice;
    };

    const showItemFreePromo = (promo) => {
        let showPromo = {
            show: false,
            text: '',
        };
        if (promo) {
            const { type } = promo;

            if (type === 1 || type === 2) {
                showPromo.show = isValidPromo(promo);

                if (type === 1) {
                    showPromo.text = '2x1';
                } else if (type === 2) {
                    showPromo.text = '3x2';
                }
            }
        }

        return showPromo;
    };

    const getNewPrice = (promo, originalPrice) => {
        let price = originalPrice;
        if (promo) {
            if (isValidPromo(promo)) {
                if (promo.type === 3) {
                    const newPrice = price - promo.discountPrice;
                    if (newPrice > 0) {
                        price = newPrice;
                    } else if (newPrice <= 0) {
                        price = 0;
                    }
                } else if (promo.type === 4) {
                    const pricePorcent =
                        (promo.discountPercent * originalPrice) / 100;
                    const newPricePorcent = price - pricePorcent;

                    price = newPricePorcent;
                }
            }
        }

        return price;
    };

    const getCountSave = (promo, originalPrice) => {
        let price = originalPrice;
        let save = '';
        if (promo) {
            if (isValidPromo(promo)) {
                if (promo.type === 3) {
                    const newPrice = price - promo.discountPrice;
                    if (newPrice > 0) {
                        price = newPrice;
                        save = `Ahorra $ ${formatMoney(promo.discountPrice)}.`;
                    } else if (newPrice <= 0) {
                        save = `Ahorra $ ${formatMoney(price)}.`;
                    }
                } else if (promo.type === 4) {
                    // const pricePorcent =
                    //     (promo.discountPercent * originalPrice) / 100;
                    // const newPricePorcent = price - pricePorcent;

                    // price = newPricePorcent;

                    save = `Ahorra el ${promo.discountPercent} %.`;
                }
            }
        }

        return save;
    };

    function isValidPromo(promo) {
        const {
            durationType,
            endDate,
            startDate,
            applicableDays,
            timeStart,
            timeEnd,
        } = promo;

        let isValid = false;

        if (durationType === 'range-dates') {
            const startDatePromo = moment.unix(startDate);
            const endDatePromo = moment.unix(endDate);
            if (
                moment(now).isBefore(endDatePromo) &&
                moment(now).isSameOrAfter(startDatePromo)
            ) {
                isValid = true;
            } else {
                isValid = false;
            }
        } else {
            const daysPromoArray = applicableDays.split(',');
            let nowDay = moment().days();
            if (nowDay === 0) {
                //asign sunday value 7
                nowDay = 7;
            }
            //search rest of the days
            const found = daysPromoArray.find(
                (element) => parseInt(element) === nowDay
            );

            if (found) {
                // isValid = true;
                //CHECK TIME
                // if (
                //     moment(now).isBetween(
                //         moment.unix(timeStart, 'h:mma'),
                //         moment.unix(timeEnd, 'h:mma')
                //     )
                // )
                if (
                    now.hour() >= moment.unix(timeStart, 'h:mma').hour() &&
                    now.hour() <= moment.unix(timeEnd, 'h:mma').hour()
                ) {
                    isValid = true;
                } else {
                    isValid = false;
                }
            } else {
                isValid = false;
            }
        }

        // return true;
        return isValid;
    }

    const handleClose = () => {
        AdviceApplySeen(token, itemActive.id).then((response) => {
            if (response === undefined) {
                const msg = messageError();

                notification['error']({
                    message: msg,
                });
                return;
            }
            if (response.statusCode === 200) {
                setAdviceSeen(true);
            }
        });
        setIsModalVisibleAdvice(false);
    };

    return (
        <>
            {loading === false && (
                <>
                    <Spin spinning={loadingItems} tip='Cargando...'>
                        <Tabs
                            activeKey={activeKey}
                            defaultActiveKey={activeKey}
                            centered
                            className={
                                'menuCustomer ' +
                                (counterItem > 0 ? 'active-scroll' : '')
                            }
                            onChange={callbackGroup}
                        >
                            {group.length > 0 &&
                                group.map((group) => {
                                    return (
                                        <Fragment key={'group' + group.groupId}>
                                            {group?.categories.length > 0 && (
                                                <TabPane
                                                    tab={group.name}
                                                    key={group.groupId}
                                                >
                                                    {group?.categories?.length >
                                                        0 &&
                                                        group.categories.map(
                                                            (cat) => {
                                                                return (
                                                                    <Fragment
                                                                        key={
                                                                            cat.categoryId
                                                                        }
                                                                    >
                                                                        {cat
                                                                            .items
                                                                            .length >
                                                                            0 && (
                                                                            <Divider
                                                                                orientation='left'
                                                                                plain
                                                                            >
                                                                                <Title
                                                                                    level={
                                                                                        4
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        cat.name
                                                                                    }
                                                                                </Title>
                                                                            </Divider>
                                                                        )}

                                                                        {cat
                                                                            .items
                                                                            .length >
                                                                            0 &&
                                                                            cat.items.map(
                                                                                (
                                                                                    item
                                                                                ) => {
                                                                                    return (
                                                                                        <Card
                                                                                            className='card-dish'
                                                                                            key={
                                                                                                item.itemId
                                                                                            }
                                                                                        >
                                                                                            <Row>
                                                                                                <Col
                                                                                                    span={
                                                                                                        14
                                                                                                    }
                                                                                                >
                                                                                                    {suscriptionCompany !==
                                                                                                        'Basic' &&
                                                                                                        getNewPrice(
                                                                                                            item.companyCoupon,
                                                                                                            item.price
                                                                                                        ) !==
                                                                                                            item.price && (
                                                                                                            <p className='banner-discount'>
                                                                                                                <TagFilled />
                                                                                                                {getCountSave(
                                                                                                                    item.companyCoupon,
                                                                                                                    item.price
                                                                                                                )}
                                                                                                            </p>
                                                                                                        )}
                                                                                                    {suscriptionCompany !==
                                                                                                        'Basic' &&
                                                                                                        showItemFreePromo(
                                                                                                            item.companyCoupon
                                                                                                        )
                                                                                                            .show && (
                                                                                                            <p className='banner-discount item-free'>
                                                                                                                <TagFilled />
                                                                                                                {
                                                                                                                    showItemFreePromo(
                                                                                                                        item.companyCoupon
                                                                                                                    )
                                                                                                                        .text
                                                                                                                }
                                                                                                            </p>
                                                                                                        )}

                                                                                                    <Title
                                                                                                        level={
                                                                                                            4
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            item.name
                                                                                                        }
                                                                                                    </Title>
                                                                                                    {!item.available && (
                                                                                                        <p className='card-dish__noAvailable'>
                                                                                                            No
                                                                                                            está
                                                                                                            disponible
                                                                                                        </p>
                                                                                                    )}
                                                                                                    <p className='card-dish__description'>
                                                                                                        {
                                                                                                            item.description
                                                                                                        }
                                                                                                    </p>
                                                                                                    <p>
                                                                                                        $
                                                                                                        {suscriptionCompany ===
                                                                                                            'Basic' &&
                                                                                                            formatMoney(
                                                                                                                item.price
                                                                                                            )}
                                                                                                        {suscriptionCompany !==
                                                                                                            'Basic' &&
                                                                                                            formatMoney(
                                                                                                                getNewPrice(
                                                                                                                    item.companyCoupon,
                                                                                                                    item.price
                                                                                                                )
                                                                                                            )}
                                                                                                        {suscriptionCompany !==
                                                                                                            'Basic' &&
                                                                                                            showPriceBefore(
                                                                                                                item.companyCoupon
                                                                                                            ) &&
                                                                                                            getNewPrice(
                                                                                                                item.companyCoupon,
                                                                                                                item.price
                                                                                                            ) !==
                                                                                                                item.price && (
                                                                                                                <Text
                                                                                                                    type='secondary'
                                                                                                                    delete
                                                                                                                    style={{
                                                                                                                        marginLeft: 8,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    $
                                                                                                                    {formatMoney(
                                                                                                                        item.price
                                                                                                                    )}
                                                                                                                </Text>
                                                                                                            )}
                                                                                                    </p>
                                                                                                    <Button
                                                                                                        icon={
                                                                                                            <PlusOutlined />
                                                                                                        }
                                                                                                        danger
                                                                                                        onClick={() =>
                                                                                                            showModal(
                                                                                                                item,
                                                                                                                ''
                                                                                                            )
                                                                                                        }
                                                                                                        disabled={
                                                                                                            item.available
                                                                                                                ? false
                                                                                                                : true
                                                                                                        }
                                                                                                    >
                                                                                                        Agregar
                                                                                                    </Button>
                                                                                                </Col>
                                                                                                <Col
                                                                                                    span={
                                                                                                        10
                                                                                                    }
                                                                                                    className='text-right'
                                                                                                >
                                                                                                    {item.itemImage !==
                                                                                                        null && (
                                                                                                        <Image
                                                                                                            width={
                                                                                                                120
                                                                                                            }
                                                                                                            src={
                                                                                                                item.itemImage !==
                                                                                                                null
                                                                                                                    ? `data:image/${item.itemImage.extension};base64, ${item.itemImage.imageData}`
                                                                                                                    : food
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                    {item.itemImage !==
                                                                                                        null && (
                                                                                                        <Button
                                                                                                            icon={
                                                                                                                <CameraOutlined />
                                                                                                            }
                                                                                                            type='link'
                                                                                                            onClick={() =>
                                                                                                                showModal(
                                                                                                                    item,
                                                                                                                    'Lista de Imágenes'
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            Imágenes
                                                                                                        </Button>
                                                                                                    )}
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Card>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        {/* </Spin> */}
                                                                    </Fragment>
                                                                );
                                                            }
                                                        )}
                                                </TabPane>
                                            )}
                                        </Fragment>
                                    );
                                })}
                        </Tabs>
                        {group.length === 0 && (
                            <Empty description={'No hay platillos'} />
                        )}
                    </Spin>
                    {counterItem > 0 && (
                        <div className='menu-costumer-button'>
                            <Button
                                type='primary'
                                block
                                danger
                                size='large'
                                onClick={() => showModal(undefined, 'Order')}
                            >
                                <Row>
                                    <Col
                                        span={4}
                                        className='menu-costumer-button__left'
                                    >
                                        <div className='counter-square'>
                                            {counterItem}
                                        </div>
                                    </Col>

                                    <Col
                                        span={10}
                                        className='menu-costumer-button__center'
                                    >
                                        Ver Canasta
                                    </Col>

                                    <Col
                                        span={10}
                                        className='menu-costumer-button__right'
                                    >
                                        <p>Total ${formatMoney(Subtotal)}</p>
                                        {/* <p>Total ${formatMoney(totalOrder)}</p> */}
                                    </Col>
                                </Row>
                            </Button>
                        </div>
                    )}
                </>
            )}
            {loading === true && (
                <List
                    itemLayout='vertical'
                    size='large'
                    dataSource={listData}
                    renderItem={(item) => (
                        <List.Item key={item.title}>
                            <Skeleton loading={loading} active avatar>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.avatar} />}
                                    title={<a href={item.href}>{item.title}</a>}
                                    description={item.description}
                                />
                                {item.content}
                            </Skeleton>
                        </List.Item>
                    )}
                />
            )}
            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={titleModal}
                footer={false}
                destroyOnClose
            >
                {contextModal}
            </Modal>
            <Modal
                className='modal-class'
                isVisible={isModalVisibleAdvice}
                setIsVisible={setIsModalVisibleAdvice}
                title={titleModalAdvice}
                footer={false}
                destroyOnClose
                onCancel={handleClose}
            >
                {contextModalAdvice}
            </Modal>
        </>
    );
};

export default MenuCustomer;
