import React, { useEffect, useState } from 'react';
import {
    Image,
    Form,
    Input,
    Select,
    Button,
    Upload,
    Checkbox,
    InputNumber,
    Tooltip,
    Divider,
    Tag,
    Skeleton,
    message,
    Row,
    Col,
    notification,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
    updateCompanyApi,
    getCompanyIdApi,
    updateLogoCompanyApi,
    getCategoriesApi,
    getCompanySuscriptionApi,
} from '../../../api/company';
import { getAccessTokenApi } from '../../../api/auth';
import { mexicanStates } from '../../../utils/mexicanStates';
import Modal from '../../../components/General/Modal';
import AddressForm from '../../../components/Client/AddressForm';
import { suscriptionType } from '../../../utils/general';

import './PerfilForm.scss';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 2,
        span: 24,
    },
};

const tailLayoutButton = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
};

export default function PerfilForm(props) {
    const {
        perfilForm,
        setPerfilForm,
        loading,
        imagePerfil,
        showDelivery,
        stateAddress,
    } = props;
    const { Option } = Select;
    const [image, setImage] = useState({
        imageUrl: '',
    });

    const { imageUrl } = image;

    const [filesList, setFileList] = useState({});

    const [disableButton, setDisableButton] = useState(true);
    const [showCostDelivery, setshowCostDelivery] = useState(showDelivery);
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [showErrorPayload, setShowErrorPayload] = useState(false);
    const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
    const [addressRest, setAddressRest] = useState({
        address: null,
        zipCode: null,
        neighborhood: null,
        city: null,
        state: null,
    });

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [suscriptionCompany, setSuscriptionCompany] = useState('Basic');

    useEffect(() => {
        setshowCostDelivery(showDelivery);
    }, [showDelivery]);
    useEffect(() => {
        const token = getAccessTokenApi();
        if (companyId) {
            getCompanySuscriptionApi(token, companyId)
                .then((response) => {
                    if (response) {
                        if (response.statusCode === 200) {
                            const result = response.result;
                            setSuscriptionCompany(
                                suscriptionType(result.suscriptionType)
                            );
                        }
                    }
                })
                .catch((err) => {});
        }
    }, [companyId]);
    useEffect(() => {
        if (
            addressRest.neighborhood &&
            addressRest.address &&
            addressRest.zipCode &&
            addressRest.city &&
            addressRest.state
        ) {
            setPerfilForm([
                {
                    name: ['Address'],
                    value: addressRest.address,
                },
                {
                    name: ['ZipCode'],
                    value: addressRest.zipCode.toString(),
                },
                {
                    name: ['Neighborhood'],
                    value: addressRest.neighborhood,
                },
                {
                    name: ['City'],
                    value: addressRest.city,
                },
                {
                    name: ['State'],
                    value: addressRest.state,
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressRest]);

    useEffect(() => {
        getCategoriesApi().then((response) => {
            let categories = [];

            if (response) {
                response.forEach((category) => {
                    categories.push({
                        value: category.companyCategoryId,
                        label: category.name,
                    });
                });
                setCategoryOptions(categories);
            }
        });
    }, []);

    const changeCheck = (e) => {
        setshowCostDelivery(e.target.checked);
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir archivos JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('La imegen debe ser menos de 2MB!');
        }
        //   return isJpgOrPng && isLt2M;
        return false;
    }

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        if (Array.isArray(fileList) && fileList.length) {
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            let file = fileList[0];

            getBase64(file.originFileObj, (imageUrl) =>
                setImage({
                    imageUrl: imageUrl,
                })
            );
        } else {
            setImage({
                imageUrl: null,
            });
        }
        setFileList({ fileList });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList.slice(-1);
    };

    // const options = [
    //     { value: 'gold' },
    //     { value: 'lime' },
    //     { value: 'green' },
    //     { value: 'cyan' },
    // ];

    function tagRender(props) {
        const { label, closable, onClose } = props;

        return (
            <Tag
                color='blue'
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    const onFinish = async (values) => {
        if (values.CashOnly === false && values.CreditCard === false) {
            notification['error']({
                message: 'Hubo un problema, debe seleccionar un tipo de pago',
            });
            setShowErrorPayload(true);
            setDisableButton(false);

            return false;
        }

        if (values.DeliverryMaxDistance === null) {
            values.DeliverryMaxDistance = 0;
        }

        if (values.DeliveryCostKM === null) {
            values.DeliveryCostKM = 0;
        }

        if (values.DeliveryCost === null) {
            values.DeliveryCost = 0;
        }

        if (values.DeliveryExtraKmCost === null) {
            values.DeliveryExtraKmCost = 0;
        }

        if (values.DeliveryCostKM > values.DeliverryMaxDistance) {
            notification['error']({
                message:
                    'La "distancia base de envío" debe ser MENOR que la "distancia máxima de envío".',
            });
            setDisableButton(false);
            return;
        }

        let formData = values;

        formData['CompanyId'] = companyId;
        formData['Phone'] = formData['Phone'].toString();
        // formData["lat"] = 27.490668;
        // formData["lon"] = -109.9614298;
        // formData["fb"] = 'http://www.facebook.com/Maryscoco"';

        setPerfilForm();

        if (values.upload !== undefined) {
            if (values.upload.length > 0) {
                let image = values.upload[0];

                let posData64 = image.thumbUrl.indexOf(',') + 1;
                var posType = image.type.indexOf('/') + 1;

                let dataImage = {
                    CompanyId: companyId,
                    ImageData: image.thumbUrl.substr(posData64),
                    Extension: image.type.substr(posType),
                };

                const resultImage = await updateLogoCompanyApi(
                    token,
                    dataImage
                );

                if (resultImage.statusCode !== 200) {
                    notification['error']({
                        message: 'Hubo un problema al actualizar la imagen',
                    });
                    setDisableButton(false);
                }
            }
        }

        const result = await updateCompanyApi(token, formData);
        if (result.statusCode === 200) {
            if (showErrorPayload) {
                setShowErrorPayload(false);
            }
            setPerfilForm([
                {
                    name: ['upload'],
                    value: [],
                },
            ]);
            notification['success']({
                message: 'Perfil modificado exitosamente',
            });

            setDisableButton(true);
        } else {
            let message = 'Error al actualizar la infromación';
            if (result) {
                if (result.statusCode !== 400) {
                    if (result.errors.RFC) {
                        message = result.errors.RFC[0];
                    }
                    if (result.errors.URL) {
                        message =
                            'El campo PÁGINA WEB no es una dirección válida.';
                    }
                }
            }
            notification['error']({
                message: message,
            });

            setDisableButton(false);
        }
    };

    return (
        <>
            <div className='perfil-form__container-image'>
                {!loading && (
                    <Image
                        width={200}
                        src={imageUrl ? imageUrl : imagePerfil}
                    />
                )}
            </div>

            <Skeleton
                loading={loading ? true : false}
                active={true}
                paragraph={true}
            />

            <Form
                className='perfil-form'
                {...layout}
                layout='horizontal'
                fields={perfilForm}
                scrollToFirstError={true}
                onFieldsChange={(changedFields, allFields) => {
                    setPerfilForm(allFields);
                    if (disableButton !== false) {
                        setDisableButton(false);
                    }
                }}
                onFinish={onFinish}
                requiredMark={false}
                size='large'
            >
                <Form.Item
                    name='upload'
                    label='Logo'
                    valuePropName='fileList'
                    getValueFromEvent={normFile}
                    extra='Imagen adjunta'
                    hidden={loading}
                >
                    <Upload
                        name='logo'
                        beforeUpload={beforeUpload}
                        listType='picture'
                        multiple={false}
                        onChange={handleChange}
                        fileList={filesList}
                    >
                        <Button icon={<UploadOutlined />}>
                            Click para cargar la imagen
                        </Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name='Name'
                    label='Nombre'
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name='CompanyCategoryIds'
                    label='Categoría'
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un categoría',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                // console.log(value);
                                if (!value || value.length <= 3) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Solo puede agregar máximo 3 categorías'
                                );
                            },
                        }),
                    ]}
                >
                    <Select
                        mode='multiple'
                        // showArrow
                        showSearch
                        tagRender={tagRender}
                        style={{ width: '100%' }}
                        options={categoryOptions}
                        placeholder='Seleccione una categoría'
                        optionFilterProp='label'
                        filterOption
                    />
                </Form.Item>

                <Form.Item name='URL' label='Página Web' hidden={loading}>
                    <Input />
                </Form.Item>

                <Form.Item
                    name='Email'
                    label='Correo electrónico de notificaciones'
                    hidden={loading}
                    rules={[
                        {
                            type: 'email',
                            message: 'El correo electrónico no es válido',
                        },
                        {
                            required: true,
                            message: 'Por favor ingrese un correo electrónico',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name='Phone'
                    hidden={loading}
                    label={
                        <span>
                            Teléfono&nbsp;
                            <Tooltip title='Tú número telefónico debe contener 10 dígitos'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    rules={[
                        {
                            required: true,
                            message:
                                'Por favor ingresa un número telefónico válido',
                            pattern: '[0-9]{10}',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || value.toString().length === 10) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Por favor ingresa tú número telefónico a 10 dígitos'
                                );
                            },
                        }),
                    ]}
                >
                    <Input placeholder='Teléfono' />
                    {/* <InputNumber
                        style={{
                            width: '100%',
                        }}
                    /> */}
                </Form.Item>

                <Form.Item
                    name='PhoneAlternate'
                    hidden={loading}
                    label={
                        <span>
                            Teléfono Alternativo&nbsp;
                            <Tooltip title='Tú número telefónico debe contener 10 dígitos'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    rules={[
                        {
                            required: false,
                            message:
                                'Por favor ingresa un número telefónico válido',
                            pattern: '[0-9]{10}',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || value.toString().length === 10) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Por favor ingresa tú número telefónico a 10 dígitos'
                                );
                            },
                        }),
                    ]}
                >
                    <Input placeholder='Teléfono' />
                </Form.Item>

                <Form.Item
                    name='RFC'
                    label='RFC'
                    hidden={loading}
                    // rules={[
                    //     {
                    //         required: false,
                    //         message: 'Por favor ingrese un RFC valido',
                    //         pattern: "^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$",
                    //     },
                    // ]}
                >
                    <Input placeholder='RFC' />
                </Form.Item>

                <Form.Item
                    name='FiscalName'
                    label='Razón Social'
                    hidden={loading}
                >
                    <Input placeholder='Razón Social' />
                </Form.Item>

                <Divider>
                    <h1>Ubicación</h1>
                </Divider>

                <Skeleton
                    loading={loading ? true : false}
                    active={true}
                    paragraph={true}
                />

                {!loading && (
                    <Form.Item
                        className='perfil-form__container-button'
                        hidden={loading}
                        {...tailLayoutButton}
                    >
                        <Button
                            icon={<EditOutlined />}
                            type='primary'
                            size='large'
                            block
                            onClick={() => setIsModalVisibleAddress(true)}
                            style={{ marginBottom: 20 }}
                            ghost
                        >
                            {stateAddress === 'add'
                                ? 'Agregar dirección'
                                : 'Modificar dirección'}
                        </Button>
                    </Form.Item>
                )}

                <Form.Item
                    name='Address'
                    label='Calle y Número'
                    hidden={loading}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    name='ZipCode'
                    label='Código Postal'
                    hasFeedback
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese el código postal',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || value.toString().length === 5) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Por favor ingresa código postal válido'
                                );
                            },
                        }),
                    ]}
                >
                    <InputNumber
                        disabled
                        placeholder='Código Postal'
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name='Neighborhood'
                    label='Colonia'
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    name='City'
                    label='Ciudad'
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    disabled
                    name='State'
                    label='Estado'
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select disabled placeholder='Seleccione un estado'>
                        {mexicanStates.map((state) => {
                            return (
                                <Option key={state} value={state}>
                                    {state}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Divider className='perfil-form__divider'>
                    <h1>Servicios incluidos</h1>
                </Divider>

                <Skeleton
                    loading={loading ? true : false}
                    active={true}
                    paragraph={true}
                />

                <Row>
                    <Col
                        xs={{ span: 24, offset: 1 }}
                        lg={{ span: 4, offset: 2 }}
                    >
                        <Form.Item
                            name='Delivery'
                            hidden={loading}
                            valuePropName='checked'
                            className='perfil-form__services'
                            {...tailLayout}
                        >
                            <Checkbox onChange={(e) => changeCheck(e)}>
                                Envío a domicilio
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24, offset: 1 }}
                        lg={{ span: 4, offset: 2 }}
                    >
                        <Form.Item
                            name='PickUp'
                            hidden={loading}
                            valuePropName='checked'
                            className='perfil-form__services'
                            {...tailLayout}
                        >
                            <Checkbox>Para llevar</Checkbox>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={{ span: 24, offset: 1 }} lg={{ span: 4, offset: 2 }}>
                        <Form.Item name="ToTable" hidden={loading} valuePropName="checked"  className="perfil-form__services" {...tailLayout}>
                            <Checkbox>A la mesa</Checkbox>
                        </Form.Item>
                    </Col> */}

                    <Col
                        xs={{ span: 24, offset: 1 }}
                        lg={{ span: 4, offset: 2 }}
                    >
                        <Form.Item
                            name='CashOnly'
                            hidden={loading}
                            valuePropName='checked'
                            className='perfil-form__services'
                            {...tailLayout}
                        >
                            <Checkbox>Acepta efectivo</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24, offset: 1 }}
                        lg={{ span: 4, offset: 2 }}
                    >
                        <Form.Item
                            name='CreditCard'
                            hidden={loading}
                            valuePropName='checked'
                            className='perfil-form__services'
                            {...tailLayout}
                        >
                            <Checkbox>Acepta pago con tarjeta</Checkbox>
                        </Form.Item>
                    </Col>
                    {showErrorPayload && (
                        <Col
                            xs={{ span: 24, offset: 1 }}
                            lg={{ span: 4, offset: 2 }}
                        >
                            <p className='error-payload'>
                                Debe seleccionar un método de pago.
                            </p>
                        </Col>
                    )}
                </Row>

                {showCostDelivery && (
                    <Form.Item
                        name='DeliverryMaxDistance'
                        label='Distancia máxima de envío (Km)'
                        hidden={loading}
                        className='perfil-form__deliveryCost'
                        dependencies={['Delivery']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (
                                        getFieldValue('Delivery') === true &&
                                        value !== undefined &&
                                        getFieldValue('Delivery') === true &&
                                        value !== null
                                    ) {
                                        return Promise.resolve();
                                    }
                                    if (
                                        getFieldValue('Delivery') === false ||
                                        getFieldValue('Delivery') === undefined
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        'Debe ingresar una distancia máxima de envío'
                                    );
                                },
                            }),
                        ]}
                    >
                        <InputNumber
                            formatter={(value) =>
                                ` ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                )
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{
                                width: '100%',
                            }}
                            min={0}
                            maxLength={6}
                        />
                    </Form.Item>
                )}

                {showCostDelivery && (
                    <>
                        <Form.Item
                            name='DeliveryCostKM'
                            label='Distancia base de envío (Km)'
                            hidden={loading}
                            className='perfil-form__deliveryCost'
                            dependencies={['Delivery', 'DeliverryMaxDistance']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            getFieldValue('Delivery') ===
                                                true &&
                                            value !== undefined &&
                                            getFieldValue('Delivery') ===
                                                true &&
                                            value !== null
                                        ) {
                                            return Promise.resolve();
                                        }
                                        if (
                                            getFieldValue('Delivery') ===
                                                false ||
                                            getFieldValue('Delivery') ===
                                                undefined
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Debe ingresar una distancia base de envío '
                                        );
                                    },
                                }),
                            ]}
                        >
                            <InputNumber
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                style={{
                                    width: '100%',
                                }}
                                min={0}
                                maxLength={5}
                            />
                        </Form.Item>

                        <Form.Item
                            name='DeliveryCost'
                            label='Costo de envío'
                            hidden={loading}
                            className='perfil-form__deliveryCost'
                            dependencies={['Delivery']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            getFieldValue('Delivery') ===
                                                true &&
                                            value !== undefined &&
                                            getFieldValue('Delivery') ===
                                                true &&
                                            value !== null
                                        ) {
                                            return Promise.resolve();
                                        }
                                        if (
                                            getFieldValue('Delivery') ===
                                                false ||
                                            getFieldValue('Delivery') ===
                                                undefined
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Debe ingresar un Costo de envío '
                                        );
                                    },
                                }),
                            ]}
                        >
                            <InputNumber
                                formatter={(value) =>
                                    `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                style={{
                                    width: '100%',
                                }}
                                min={0}
                            />
                        </Form.Item>
                    </>
                )}

                {showCostDelivery && (
                    <Form.Item
                        name='DeliveryExtraKmCost'
                        label='Costo de envío extra /km'
                        hidden={loading}
                        className='perfil-form__deliveryCost'
                        dependencies={['Delivery']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (
                                        getFieldValue('Delivery') === true &&
                                        value !== undefined &&
                                        getFieldValue('Delivery') === true &&
                                        value !== null
                                    ) {
                                        return Promise.resolve();
                                    }
                                    if (
                                        getFieldValue('Delivery') === false ||
                                        getFieldValue('Delivery') === undefined
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        'Debe ingresar una distancia máxima de envío'
                                    );
                                },
                            }),
                        ]}
                    >
                        <InputNumber
                            formatter={(value) =>
                                `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                )
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{
                                width: '100%',
                            }}
                            min={0}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    name='OrderMinimumPurchase'
                    label='Compra mínima'
                    hidden={loading}
                    className='perfil-form__deliveryCost'
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber
                        formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{
                            width: '100%',
                        }}
                        min={0}
                    />
                </Form.Item>

                <Form.Item
                    name='ServiceMaxDistance'
                    hidden={loading}
                    valuePropName='checked'
                    // className="perfil-form__services"
                >
                    <Checkbox>
                        Acepta pedidos fuera de la distancia máxima de envio
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    name='AllowMultipleActiveOrders'
                    hidden={loading}
                    valuePropName='checked'
                    tooltip='Aceptar que el cliente pueda realizar varios pedidos teniendo órdenes activas.'
                    label='Múltiples pedidos'
                >
                    <Checkbox>Acepta múltiples pedidos activos</Checkbox>
                </Form.Item>
                <Divider>Tiempo de preparación</Divider>
                <Form.Item
                    name='DefaultOrderPreparationTime'
                    label='Minutos de preparación por default'
                    className='perfil-form__deliveryCost'
                    // tooltip="Especificar el tiempo que ll"
                >
                    <InputNumber
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        style={{
                            width: '100%',
                        }}
                        min={0}
                    />
                </Form.Item>
                {suscriptionCompany !== 'Basic' && (
                    <>
                        <Divider>Runner</Divider>
                        <Form.Item
                            name='PickUpIndications'
                            label='Instrucciones de recogida'
                            tooltip='Especificar las instrucciones de recogida que tendrá el runner.'
                        >
                            <Input.TextArea maxLength={400} />
                        </Form.Item>
                    </>
                )}

                <Form.Item
                    className='perfil-form__container-button'
                    hidden={loading}
                    {...tailLayoutButton}
                >
                    <Button
                        type='primary'
                        htmlType='submit'
                        block={true}
                        size='large'
                        className={[
                            'perfil-form__button',
                            !showCostDelivery && 'margin-button-perfil',
                        ]}
                        disabled={disableButton}
                    >
                        Guardar
                    </Button>
                </Form.Item>

                <Modal
                    isVisible={isModalVisibleAddress}
                    setIsVisible={setIsModalVisibleAddress}
                    title={'Dirección del restaurante'}
                    footer={false}
                    destroyOnClose
                >
                    <AddressForm
                        type='adminPerfil'
                        setAddressRest={setAddressRest}
                        setIsModalVisibleAddress={setIsModalVisibleAddress}
                        setDisableButton={setDisableButton}
                    />
                </Modal>
            </Form>
        </>
    );
}
