import React, { useState } from 'react';
import {
    Form,
    Button,
    Select,
    notification,
    Spin,
    Input,
    Typography,
} from 'antd';
import { getAccessTokenApi } from '../../../../api/auth';
import { paymentServiceRestApi } from '../../../../api/superAdmin';
import { messageError } from '../../../../utils/general';

export default function PaymentRest(props) {
    const {
        companyId,
        setIsVisibleModal,
        useConekta,
        useStripe,
        setReloadRestaurants,
    } = props;
    const [loading, setLoading] = useState(false);
    const [showPublicKey, setShowPublicKey] = useState(false);
    const token = getAccessTokenApi();
    const [form] = Form.useForm();
    const { Option } = Select;
    const { Paragraph, Text } = Typography;

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 24 },
    };

    function ChangePayment(value) {
        if (value === 1) {
            setShowPublicKey(true);
        } else {
            setShowPublicKey(false);
        }
    }

    const onFinish = (values) => {
        setLoading(true);

        paymentServiceRestApi(token, companyId, values).then((response) => {
            if (response) {
                if (response.statusCode === 200) {
                    notification['success']({
                        message: 'Actualización exitosa.',
                    });
                    setLoading(false);
                    setIsVisibleModal(false);
                    setReloadRestaurants(true);
                } else {
                    let msg = messageError();

                    if (
                        response.statusCode === 409 ||
                        response.statusCode === 404 ||
                        response.statusCode === 400
                    ) {
                        msg = response.description;
                    }

                    notification['error']({
                        message: msg,
                    });
                    setLoading(false);
                }
            } else {
                let msg = messageError();

                notification['error']({
                    message: msg,
                });
                setLoading(false);
            }
        });
    };

    return (
        <Spin spinning={loading} tip='Cargando...'>
            <Paragraph strong>
                <Text type='secondary'>Método de pago actual: </Text>
                {useConekta ? 'Conekta' : useStripe ? 'Stripe' : 'Ninguno'}
            </Paragraph>
            <Form
                {...layout}
                form={form}
                name='control-hooks'
                onFinish={onFinish}
            >
                <Form.Item
                    name='Type'
                    label='Método de Pago'
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder='Seleccione un tipo'
                        onChange={ChangePayment}
                    >
                        <Option value={0} disabled={useStripe}>
                            Stripe
                        </Option>
                        <Option value={1} disabled={useConekta}>
                            Conekta
                        </Option>
                    </Select>
                </Form.Item>

                {showPublicKey && (
                    <Form.Item
                        tooltip='Este dato lo encuentra el establecimiento en su perfil en el sitio del método de pago seleccionado como (llave privada de produccion)'
                        name='ServiceKey'
                        label='Private Key'
                        rules={[{ required: true }]}
                    >
                        <Input placeholder='key_jo23inq13o2iEjemploetc' />
                    </Form.Item>
                )}

                {showPublicKey && (
                    <Form.Item
                        tooltip='Este dato lo encuentra el establecimiento en su perfil en el sitio del método de pago seleccionado como (llave  publica de produccion)'
                        name='ConektaPublicKey'
                        label='Public Key'
                        rules={[{ required: true }]}
                    >
                        <Input placeholder='key_jo23inq13o2iEjemploetc' />
                    </Form.Item>
                )}

                <Form.Item {...tailLayout}>
                    <Button type='primary' htmlType='submit' block>
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
