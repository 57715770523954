import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Layout, Typography, Tabs } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import MySubscription from '../../../components/Admin/Subscription/MySubscription';
import Packages from '../../../components/Admin/Subscription/Packages';
import useAuth from '../../../hooks/useAuth';
import AdminHome from '../../../pages/Admin/Perfil';

import './Subscription.scss';
import AuthAdminForm from '../../../components/Admin/AuthAdminForm';
import { getAccessTokenApi } from '../../../api/auth';
import {
    getCompanyIdApi,
    getCompanySuscriptionApi,
} from '../../../api/company';

export default function Subscription() {
    const { Content } = Layout;
    const { Title } = Typography;
    const { TabPane } = Tabs;
    const { user } = useAuth();
    const [isAuth, setIsAuth] = useState(false);
    const [roleUser, setRoleUser] = useState('');
    const [tab, setTab] = useState('1');
    const companyId = getCompanyIdApi();

    useEffect(() => {
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        setRoleUser(roleId);
    }, [user]);

    useEffect(() => {
        const token = getAccessTokenApi();
        if (companyId) {
            getCompanySuscriptionApi(token, companyId)
                .then((response) => {
                    if (response) {
                        if (response.statusCode === 200) {
                            const result = response.result;

                            if (result.suscriptionType === 3) {
                                setIsAuth(true);
                            }
                        }
                    }
                })
                .catch((err) => {});
        }
    }, [companyId]);

    if (roleUser === 'Operator') {
        return (
            <>
                <Route path='/admin' component={AdminHome} />
                <Redirect to='/admin' />
            </>
        );
    }

    function callback(key) {
        setTab(key);
    }

    return (
        <Layout className='subscription'>
            <Content className='subscription_content'>
                <HeaderTop
                    title={'Suscripción'}
                    backIcon={true}
                    backUrl={'/admin'}
                />

                {roleUser === 'Admin' && isAuth && (
                    <div className='subscription__container'>
                        <Tabs
                            defaultActiveKey='pending'
                            activeKey={tab}
                            centered
                            onChange={callback}
                        >
                            <TabPane
                                tab={<Title level={3}>Mi Suscripción</Title>}
                                key='1'
                            >
                                <MySubscription setTab={setTab} />
                            </TabPane>
                            <TabPane
                                tab={<Title level={3}>Paquetes</Title>}
                                key='2'
                            >
                                <Packages />
                            </TabPane>
                        </Tabs>
                    </div>
                )}
                {!isAuth && (
                    <div className='all-report__container'>
                        <AuthAdminForm setIsAuth={setIsAuth} />
                    </div>
                )}
            </Content>
        </Layout>
    );
}
