import React, { useState, useEffect } from 'react';
import { Divider, Layout, notification, Skeleton, Col, Row, Table, Typography, Input, Space, Button } from 'antd';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import HeaderTop from '../../../components/General/HeaderTop';
import { getAccessTokenApi } from '../../../api/auth';
import { getAccessCodeCompaniesApi } from '../../../api/superAdmin';
import {
    messageError,
    messageErrorGeneral,
    userLogOut,
} from '../../../utils/general';

import './AccessCode.scss';

const AccessCode = () => {
    const { Content } = Layout;
    const [codes, setCodes] = useState([]);
    const [reloadUsers, setreloadUsers] = useState(false);
    const [loading, setLoading] = useState(true);
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        getAccessCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadUsers]);

    const getAccessCode = async () => {
        const result = await getAccessCodeCompaniesApi(token);

        if (result) {

            if (result.statusCode === 200) {
                const arrayRest = [
                    'DemoCompany',
                    'DemoEnvago',
                    'DemoRest',
                    'DemoTienda',
                    'DemoTienda1',
                ];
                let codesAccess = result.result.filter(
                    (ele) => !arrayRest.includes(ele.companyId)
                );
                codesAccess.forEach((element, index) => {
                    element['key'] = index;
                });
                setCodes(codesAccess);
            } else {
                notification['error']({
                    message: messageErrorGeneral(),
                });
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }
        setLoading(false);
    };

    return (
        <Layout className='access-code'>
            <HeaderTop
                title={'Usuarios'}
                backIcon={true}
                backUrl='/administrative'
            />

            <Content className='access-code__content'>
                {!loading && (
                    <TableCodes
                        data={codes}
                        loading={loading}
                        setreloadUsers={setreloadUsers}
                    />
                )}
                {loading && (
                    <div>
                        <div style={{ textAlign: 'right' }}>
                            <Skeleton.Button active size='large' />
                        </div>
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                )}
            </Content>
        </Layout>
    );
};

export default AccessCode;

function TableCodes(props) {
    const { data, loading } = props;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const { Title } = Typography;

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const clearAll = () => {
        setFilteredInfo({});
        // setSortedInfo({});
    };

    const columns = [
        {
            title: 'Nombre de la Compañía',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text) => <h3>{text}</h3>,
            ...getColumnSearchProps('companyName'),
        },
        {
            title: 'Compañia',
            dataIndex: 'companyId',
            key: 'companyId',
            render: (text) => <h3>{text}</h3>,
            ...getColumnSearchProps('companyId'),
        },

        {
            title: 'Código de Acceso',
            dataIndex: 'reportAccessCode',
            key: 'reportAccessCode',
            ...getColumnSearchProps('reportAccessCode'),
        },
        
    ];
    return (
        <div>
            <Row gutter={[10, 20]}>
                <Col xs={24} md={16}>
                    <Title level={2}>Lista de Códigos de Accesos</Title>
                </Col>
                <Col xs={24} md={8}>
                    <Button
                        danger
                        icon={<FilterOutlined />}
                        onClick={clearAll}
                        style={{ marginRight: 10 }}
                    >
                        Limpiar Filtros
                    </Button>
                    {/* <Button type='primary'>Crear un Usuario</Button> */}
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                onChange={handleChange}
                scroll={{ x: 950 }}
            />
        </div>
    );
    
}
