import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Affix, Button, Popconfirm, message } from 'antd';
import { LeftOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';

import './HeaderTop.scss';

export default function HeaderTop(props) {
    const {
        title,
        backIcon,
        backIconFunction,
        history,
        confirmBackIcon,
        logoCompany,
        imageCompany,
        route = '/login',
        search,
        handleSearch,
        closed,
        handleClosed,
        backIconHandle,
        goToUrl,
        textCapitalize,
    } = props;

    function cancelBackIcon() {
        history.push('/');
        message.success('Se guardara su canasta para después.');
    }

    function handleGoTo() {
        window.location.href = goToUrl;
    }

    return (
        <Affix offsetTop>
            <div className='header-top'>
                <div className='header-top__left'>
                    {backIcon && (
                        <Link className='back-btn' to={route}>
                            <LeftOutlined />
                        </Link>
                    )}

                    {backIconHandle && (
                        <Button
                            onClick={handleGoTo}
                            className='back-handle-btn'
                            type='text'
                            icon={
                                <LeftOutlined
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: 25,
                                    }}
                                />
                            }
                        />
                    )}

                    {backIconFunction && (
                        <Popconfirm
                            title='¿Desea vaciar su canasta?'
                            onConfirm={confirmBackIcon}
                            onCancel={cancelBackIcon}
                            okText='Si'
                            cancelText='No'
                        >
                            <Button
                                className='back-handle-btn'
                                type='text'
                                icon={
                                    <LeftOutlined
                                        style={{
                                            color: 'white',
                                            fontWeight: 'bold',
                                            fontSize: 25,
                                        }}
                                    />
                                }
                            />
                        </Popconfirm>
                    )}

                    {logoCompany && imageCompany !== null && (
                        <Image width={60} src={imageCompany} />
                    )}
                </div>

                <div className='header-top__center'>
                    <h1
                        className={`${textCapitalize ? 'text-capitalize' : ''}`}
                    >
                        {title}
                    </h1>
                </div>

                <div className='header-top__right'>
                    {search && (
                        <Button
                            className='search-btn'
                            type='text'
                            icon={
                                <SearchOutlined
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: 25,
                                    }}
                                />
                            }
                            onClick={handleSearch}
                        />
                    )}

                    {closed && (
                        <Button
                            className='search-btn'
                            type='text'
                            icon={
                                <CloseOutlined
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: 25,
                                    }}
                                />
                            }
                            onClick={handleClosed}
                        />
                    )}
                </div>
            </div>
        </Affix>
    );
}
